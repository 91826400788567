<template>
    <div class="container ps-2 pe-2">
        <div class="row">
            <div class="col-sm-12 p-1">

                <div class="card">

                    <div class="card-header boder-0 pt-5" :style="`background-color: #36a3f7 !important;`">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1" :style="`color: #ffffff !important;`">
                                Top Sales Person
                            </span>
                        </h3>
                    </div>

                    <div class="card-body card-custom-spacing-tblf-10 bg-white" v-if="topSalesAgent">
                        <div class="pt-0 p-0">
                            <div class="table-responsive table-simple-dash" id="displayResponseData">

                                <el-table style="overflow-y: auto" :data="topSalesAgent"
                                    class="table table-hover table-bai-striped table-row-bordered border gy-7 gs-7 mb-0">

                                        <el-table-column fixed label="Name" width="200">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.agent_name ? scope.row.agent_name : "" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="New Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.new_leads ? scope.row.new_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="New Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.new_deals ? scope.row.new_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (New)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_new ? scope.row.closing_ratio_new :
                                                "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column width="200">
                                            <template #header>
                                                <span v-html="`WIP Deals <br />(New | Rwl | LRwl | Oth)`" />
                                            </template>
                                            <template #default="scope">
                                                {{ scope.row && scope.row.wip_deals ? scope.row.wip_deals :
                                                "0" }} (
                                                {{ scope.row && scope.row.wip_new ? scope.row.wip_new :
                                                "0" }} |
                                                {{ scope.row && scope.row.wip_renewal ? scope.row.wip_renewal :
                                                "0" }} |
                                                {{ scope.row && scope.row.wip_lrwl ? scope.row.wip_lrwl :
                                                "0" }} |
                                                {{ scope.row && scope.row.wip_others ? scope.row.wip_others :
                                                "0" }} )
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Renewal Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.renewal_leads ? scope.row.renewal_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Renewal Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.renewal_deals ? scope.row.renewal_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (Renewal)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_renewal ?
                                                scope.row.closing_ratio_renewal : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="LLR Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.llr_leads ? scope.row.llr_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="LLR Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.llr_deals ? scope.row.llr_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (LLR)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_llr ?
                                                scope.row.closing_ratio_llr : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Total Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.total_leads ? scope.row.total_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Total Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.total_deals ? scope.row.total_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (Total)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_total ?
                                                scope.row.closing_ratio_total : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Total Revenue" width="120">
                                            <template #default="scope">
                                                <el-popover placement="left" width="180" trigger="hover">
                                                    <template #reference>
                                                    <span v-if="scope.row.total_revenue">
                                                        {{ scope.row && scope.row.total_revenue ? scope.row.total_revenue : "0" }}
                                                    </span>
                                                    </template>
                                                    <template #default>
                                                    <div class="card">
                                                        <div class="card-body p-2">
                                                        <table class="text-gray-600">
                                                            <tbody>
                                                            <tr class="text-gray-600" style="background-color: #fff" >
                                                                <th scope="col" class="p-2" nowrap> Revenue Target ($) </th>
                                                            </tr>
                                                            <tr class="text-gray-600" style="background-color: #fff" >
                                                                <td scope="col" class="p-2" nowrap>
                                                                {{ scope.row.revenue_target_no_of_days }}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                    </template>
                                                </el-popover>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Target % Met" width="120">
                                            <template #default="scope">
                                                <span v-if="scope.row.is_target_flag">
                                                    {{ scope.row && scope.row.target_percentage_met ? scope.row.target_percentage_met : "0" }}
                                                </span>
                                                <span v-else>
                                                    {{ '-' }}
                                                </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Lost Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.lost_leads ? scope.row.lost_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="LR Lost" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.lrl_leads ? scope.row.lrl_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (LL/LRL)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_lost_leads_and_lead_renewal_lost ? scope.row.closing_ratio_lost_leads_and_lead_renewal_lost : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Untouched Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.untouched_leads ? scope.row.untouched_leads : "0"
                                                }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Untouched Customers" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.untouched_customers ?
                                                scope.row.untouched_customers : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Over Due Tasks" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.over_due_tasks ? scope.row.over_due_tasks : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Pending Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.pending_leads ? scope.row.pending_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Deals Cancelled" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.total_deals_cancelled ?
                                                scope.row.total_deals_cancelled : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Cancellation Amount" width="150">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.deals_cancellation_payable_amount ?
                                                scope.row.deals_cancellation_payable_amount : "0" }}
                                            </template>
                                        </el-table-column>

                                </el-table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-12 mb-6 p-1" v-if="user.role_id != 7">
                <div class="card mt-5">

                    <div class="card-header boder-0 pt-5" :style="`background-color: #36a3f7 !important;`">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1" :style="`color: #ffffff !important;`">
                                Sales Target Summary
                            </span>
                        </h3>
                    </div>

                    <div class="card-body card-custom-spacing-tblf-10 bg-white" v-if="salesTarget">
                        <div class="pt-0 p-0">
                            <div class="table-responsive table-simple-dash" id="displayResponseData">

                                <el-table style="overflow-y: auto" :data="salesTarget"
                                    class="table table-hover table-bai-striped table-row-bordered border gy-7 gs-7 mb-0">

                                    <el-table-column fixed label="Name" width="200">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.name ? scope.row.name : "" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Total Deals" width="100">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.total_deals ? scope.row.total_deals : "0" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Sales Target" width="100">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.sales_target ? scope.row.sales_target : "0" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Refund Amount" width="100">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.refund ? currency(scope.row.refund) : "0" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Revenue Made" width="100">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.revenue_made ? currency(scope.row.revenue_made) : "0" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Revenue Target" width="120">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.revenue_target ? currency(scope.row.revenue_target) : "0" }}
                                        </template>
                                    </el-table-column>

                                </el-table>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- <div class="col-sm-12 mb-6 p-1" v-if="user.role_id != 7">
                <div class="card mt-5">

                    <div class="card-header boder-0 pt-5" :style="`background-color: #36a3f7 !important;`">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1" :style="`color: #ffffff !important;`">
                                Leads Target Summary
                            </span>
                        </h3>
                    </div>

                    <div class="card-body card-custom-spacing-tblf-10 bg-white" v-if="leadsTarget">
                        <div class="pt-0 p-0">
                            <div class="table-responsive table-simple-dash" id="displayResponseData">

                                <el-table style="overflow-y: auto" :data="leadsTarget"
                                    class="table table-hover table-bai-striped table-row-bordered border gy-7 gs-7 mb-0">

                                    <el-table-column fixed label="Marketing  Agent" width="200">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.name ? scope.row.name : "" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Number Of Leads" width="150">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.no_of_leads ? scope.row.no_of_leads : "0" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Achieved" width="100">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.achieved ? scope.row.achieved : "0" }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Marketing Spend" width="180">
                                        <template #default="scope">
                                            {{ scope.row && scope.row.marketing_spend ? currency(scope.row.marketing_spend) : "0" }}
                                        </template>
                                    </el-table-column>

                                </el-table>

                            </div>
                        </div>
                    </div>

                </div>
            </div> -->
            
            <div class="col-sm-12 mb-6 p-1">
                <div class="card">

                    <div class="card-header boder-0 pt-5" :style="`background-color: rgb(244, 81, 108) !important;`">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder fs-3 mb-1" :style="`color: #ffffff !important;`">
                                Top Sales Person (Inactive)
                            </span>
                        </h3>
                    </div>

                    <div class="card-body card-custom-spacing-tblf-10 bg-white" v-if="topSalesAgentInactive">
                        <div class="pt-0 p-0">
                            <div class="table-responsive table-simple-dash" id="displayResponseData">

                                <el-table style="overflow-y: auto" :data="topSalesAgentInactive" v-if="topSalesAgentInactive.length > 0"
                                    class="table table-hover table-bai-striped table-row-bordered border gy-7 gs-7 mb-0">

                                        <el-table-column fixed label="Name" width="200">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.agent_name ? scope.row.agent_name : "" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="New Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.new_leads ? scope.row.new_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="New Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.new_deals ? scope.row.new_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (New)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_new ? scope.row.closing_ratio_new :
                                                "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column width="200">
                                            <template #header>
                                                <span v-html="`WIP Deals <br />(New | Rwl | LRwl | Oth)`" />
                                            </template>
                                            <template #default="scope">
                                                {{ scope.row && scope.row.wip_deals ? scope.row.wip_deals :
                                                "0" }} (
                                                {{ scope.row && scope.row.wip_new ? scope.row.wip_new :
                                                "0" }} |
                                                {{ scope.row && scope.row.wip_renewal ? scope.row.wip_renewal :
                                                "0" }} |
                                                {{ scope.row && scope.row.wip_lrwl ? scope.row.wip_lrwl :
                                                "0" }} |
                                                {{ scope.row && scope.row.wip_others ? scope.row.wip_others :
                                                "0" }} )
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Renewal Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.renewal_leads ? scope.row.renewal_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Renewal Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.renewal_deals ? scope.row.renewal_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (Renewal)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_renewal ?
                                                scope.row.closing_ratio_renewal : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="LLR Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.llr_leads ? scope.row.llr_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="LLR Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.llr_deals ? scope.row.llr_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (LLR)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_llr ?
                                                scope.row.closing_ratio_llr : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Total Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.total_leads ? scope.row.total_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Total Deals" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.total_deals ? scope.row.total_deals : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (Total)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_total ?
                                                scope.row.closing_ratio_total : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Total Revenue" width="120">
                                            <template #default="scope">
                                                <el-popover placement="left" width="180" trigger="hover">
                                                    <template #reference>
                                                    <span v-if="scope.row.total_revenue">
                                                        {{ scope.row && scope.row.total_revenue ? scope.row.total_revenue : "0" }}
                                                    </span>
                                                    </template>
                                                    <template #default>
                                                    <div class="card">
                                                        <div class="card-body p-2">
                                                        <table class="text-gray-600">
                                                            <tbody>
                                                            <tr class="text-gray-600" style="background-color: #fff" >
                                                                <th scope="col" class="p-2" nowrap> Revenue Target ($) </th>
                                                            </tr>
                                                            <tr class="text-gray-600" style="background-color: #fff" >
                                                                <td scope="col" class="p-2" nowrap>
                                                                {{ scope.row.revenue_target_no_of_days }}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </div>
                                                    </template>
                                                </el-popover>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Target % Met" width="120">
                                            <template #default="scope">
                                                <span v-if="scope.row.is_target_flag">
                                                    {{ scope.row && scope.row.target_percentage_met ? scope.row.target_percentage_met : "0" }}
                                                </span>
                                                <span v-else>
                                                    {{ '-' }}
                                                </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Lost Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.lost_leads ? scope.row.lost_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="LR Lost" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.lrl_leads ? scope.row.lrl_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="CR (LL/LRL)" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.closing_ratio_lost_leads_and_lead_renewal_lost ? scope.row.closing_ratio_lost_leads_and_lead_renewal_lost : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Untouched Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.untouched_leads ? scope.row.untouched_leads : "0"
                                                }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Untouched Customers" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.untouched_customers ?
                                                scope.row.untouched_customers : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Over Due Tasks" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.over_due_tasks ? scope.row.over_due_tasks : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Pending Leads" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.pending_leads ? scope.row.pending_leads : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Deals Cancelled" width="120">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.total_deals_cancelled ?
                                                scope.row.total_deals_cancelled : "0" }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Cancellation Amount" width="150">
                                            <template #default="scope">
                                                {{ scope.row && scope.row.deals_cancellation_payable_amount ?
                                                scope.row.deals_cancellation_payable_amount : "0" }}
                                            </template>
                                        </el-table-column>

                                </el-table>
                                <div v-else>
                                    <el-empty description="No Records Available" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { registration } from '@/store/stateless/store';
import { defineComponent, computed } from 'vue'
import { useStore } from "vuex";

export default defineComponent({
    props: {
        daterange: {
            type: Array,
            required: true
        }
    },

    components: {
    },

    setup() {
        const store = useStore();

        const topSalesAgent = computed(() => {
            const datas = store.getters.getTopSalesAgent
            if (datas) {
                const value = datas.map((data) => {
                    return {
                        agent_name: data.agent_name,
                        total_leads: data.total_leads,
                        total_deals: data.total_deals,
                        new_leads: data.new_leads,
                        renewal_leads: data.renewal_leads,
                        llr_leads: data.llr_leads,
                        pending_leads: data.pending_leads,
                        untouched_customers: data.untouched_customers,
                        untouched_leads: data.untouched_leads,
                        over_due_tasks: data.over_due_tasks,
                        new_deals: data.new_deals,
                        renewal_deals: data.renewal_deals,
                        lrl_leads: data.lrl_leads,
                        lost_leads: data.lost_leads,
                        total_deals_cancelled: data.total_deals_cancelled,
                        deals_cancellation_payable_amount: currency(data.deals_cancellation_payable_amount),
                        total_revenue: currency(data.total_revenue),
                        closing_ratio_total: data.closing_ratio_total + '%', // (total_deals / total_leads) * 100
                        closing_ratio_new: data.closing_ratio_new + '%', // (new_deals / new_leads) * 100
                        closing_ratio_renewal: data.closing_ratio_renewal + '%', // (renewal_deals / renewal_leads) * 100
                        llr_deals: data.llr_deals,
                        closing_ratio_llr: data.closing_ratio_llr + '%',
                        wip_deals: data.wip_deals,
                        revenue_target_achieved: data.revenue_target_achieved + '%',
                        target_percentage_met: data.target_percentage_met + '%',
                        revenue_target_no_of_days: currency(data.revenue_target_no_of_days),
                        is_target_flag: data.is_target_flag,
                        wip_new: data.wip_new,
                        wip_renewal: data.wip_renewal,
                        wip_lrwl: data.wip_lrwl,
                        wip_others: data.wip_others,
                        closing_ratio_lost_leads_and_lead_renewal_lost: data.closing_ratio_lost_leads_and_lead_renewal_lost + '%'
                    }
                })
                if(Object.keys(totalTopSalesAgent.value).length > 0) {
                    value.push(totalTopSalesAgent.value)
                }

                return value
                
            } else {
                return []
            }
        })

        const totalTopSalesAgent = computed(() => {
            const datas = store.getters.getTopSalesAgent
            if(datas) {
                const total_deals = calculateTotal(datas, 'total_deals')
                const total_leads = calculateTotal(datas, 'total_leads')
                const new_deals = calculateTotal(datas, 'new_deals')
                const new_leads = calculateTotal(datas, 'new_leads')
                const renewal_deals = calculateTotal(datas, 'renewal_deals')
                const renewal_leads = calculateTotal(datas, 'renewal_leads')
                const closing_total = roundUp((total_deals / total_leads) * 100)
                const closing_new = roundUp((new_deals / new_leads) * 100)
                const closing_renewal = roundUp((renewal_deals / renewal_leads) * 100)
                const lost_leads = calculateTotal(datas, 'lost_leads')
                const lrl_leads = calculateTotal(datas, 'lrl_leads')
                const llr_deals = calculateTotal(datas, 'llr_deals')
                const closing_llr = roundUp((llr_deals / lrl_leads) * 100)
                const wip_deals = calculateTotal(datas, 'wip_deals')
                const wip_new = calculateTotal(datas, 'wip_new')
                const wip_renewal = calculateTotal(datas, 'wip_renewal')
                const wip_lrwl = calculateTotal(datas, 'wip_lrwl')
                const wip_others = calculateTotal(datas, 'wip_others')
                const revenue_target = calculateTotal(datas, 'revenue_target')
                const revenue_target_achieved = roundUp((calculateTotal(datas, 'total_revenue') / revenue_target) * 100)
                const revenue_target_no_of_days = calculateTotal(datas, 'revenue_target_no_of_days')
                const total_target_percentage_met = roundUp((calculateTotal(datas, 'total_revenue') / revenue_target_no_of_days) * 100)
                const is_target_flag = isFlagTrue(datas, 'is_target_flag')
                const closing_ratio_ll_and_lrl = roundUp(((calculateTotal(datas, 'lost_leads') + calculateTotal(datas, 'lrl_leads')) / calculateTotal(datas, 'total_leads')) * 100)
                return {
                    agent_name: 'Total',
                    total_leads,
                    total_deals,
                    new_leads,
                    renewal_leads,
                    llr_leads: calculateTotal(datas, 'llr_leads'),
                    pending_leads: calculateTotal(datas, 'pending_leads'),
                    untouched_customers: calculateTotal(datas, 'untouched_customers'),
                    untouched_leads: calculateTotal(datas, 'untouched_leads'),
                    over_due_tasks: calculateTotal(datas, 'over_due_tasks'),
                    new_deals,
                    renewal_deals,
                    total_deals_cancelled: calculateTotal(datas, 'total_deals_cancelled'),
                    deals_cancellation_payable_amount: currency(roundUp(calculateTotal(datas, 'deals_cancellation_payable_amount'))),
                    total_revenue: currency(roundUp(calculateTotal(datas, 'total_revenue'))),
                    closing_ratio_total: isFinite(closing_total) ? `${roundUp(closing_total)}%` : `0.00%`,
                    closing_ratio_new: isFinite(closing_new) ? `${roundUp(closing_new)}%` : `0.00%`,
                    closing_ratio_renewal: isFinite(closing_renewal) ? `${roundUp(closing_renewal)}%` : `0.00%`,
                    lost_leads,
                    lrl_leads,
                    llr_deals,
                    closing_ratio_llr: isFinite(closing_llr) ? `${roundUp(closing_llr)}%` : `0.00%`,
                    wip_deals,
                    wip_new,
                    wip_renewal,
                    wip_lrwl,
                    wip_others,
                    revenue_target_achieved: isFinite(revenue_target_achieved) ? `${roundUp(revenue_target_achieved)}%` : `0.00%`,
                    target_percentage_met: isFinite(total_target_percentage_met) ? `${roundUp(total_target_percentage_met)}%` : (is_target_flag ? `0.00%` : '-'),
                    revenue_target_no_of_days: isFinite(revenue_target_no_of_days) ? `${currency(roundUp(revenue_target_no_of_days))}` : `-`,
                    is_target_flag:is_target_flag,
                    closing_ratio_lost_leads_and_lead_renewal_lost: isFinite(closing_ratio_ll_and_lrl) ? `${roundUp(closing_ratio_ll_and_lrl)}%` : `0.00%`
                }
            }

            return {}
        })

        const calculateTotal = (data, column) => {
            return data.reduce((total, item) => {
                    if(column  == 'revenue_target'){
                        item[column] = item[column].replaceAll(',','');
                    } 
                    if(typeof item[column] === 'string') {
                        return total += parseFloat(item[column])
                    } else {
                        return total += item[column]
                    }
            }, 0)
        }

        const roundUp = (num) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }


        const salesTarget = computed(() => {
            return store.getters.getSalesTargetDashboard
        })

        const leadsTarget = computed(() => {
            return store.getters.getLeadsTargetDashboard
        })

        const user = computed(() => {
            return store.getters.currentUser
        })

        const currency = (e) => {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: registration.currency,
                maximumFractionDigits: 2,
            });

            return formatter.format(e).replace(registration.currency, "");
        }

        const isFlagTrue = (data, column) => {
            return data.map((item) => {
                return item[column]
            }, false)
        }

        const topSalesAgentInactive = computed(() => {
            const datas = store.getters.getTopSalesAgentInactive
            if (datas) {
                const value = datas.map((data) => {
                    return {
                        agent_name: data.agent_name,
                        total_leads: data.total_leads,
                        total_deals: data.total_deals,
                        new_leads: data.new_leads,
                        renewal_leads: data.renewal_leads,
                        llr_leads: data.llr_leads,
                        pending_leads: data.pending_leads,
                        untouched_customers: data.untouched_customers,
                        untouched_leads: data.untouched_leads,
                        over_due_tasks: data.over_due_tasks,
                        new_deals: data.new_deals,
                        renewal_deals: data.renewal_deals,
                        lrl_leads: data.lrl_leads,
                        lost_leads: data.lost_leads,
                        total_deals_cancelled: data.total_deals_cancelled,
                        deals_cancellation_payable_amount: currency(data.deals_cancellation_payable_amount),
                        total_revenue: currency(data.total_revenue),
                        closing_ratio_total: data.closing_ratio_total + '%', // (total_deals / total_leads) * 100
                        closing_ratio_new: data.closing_ratio_new + '%', // (new_deals / new_leads) * 100
                        closing_ratio_renewal: data.closing_ratio_renewal + '%', // (renewal_deals / renewal_leads) * 100
                        llr_deals: data.llr_deals,
                        closing_ratio_llr: data.closing_ratio_llr + '%',
                        wip_deals: data.wip_deals,
                        revenue_target_achieved: data.revenue_target_achieved + '%',
                        target_percentage_met: data.target_percentage_met + '%',
                        revenue_target_no_of_days: currency(data.revenue_target_no_of_days),
                        is_target_flag: data.is_target_flag,
                        wip_new: data.wip_new,
                        wip_renewal: data.wip_renewal,
                        wip_lrwl: data.wip_lrwl,
                        wip_others: data.wip_others,
                        closing_ratio_lost_leads_and_lead_renewal_lost: data.closing_ratio_lost_leads_and_lead_renewal_lost + '%'
                    }
                })
                
                if(Object.keys(totalTopSalesAgentInactive.value).length > 0) {
                    value.push(totalTopSalesAgentInactive.value)
                }

                return value
                
            } else {
                return []
            }
        })

        const totalTopSalesAgentInactive = computed(() => {
            const datas = store.getters.getTopSalesAgentInactive
            
            if(datas.length > 0) {
                
                if(datas) {
                    const total_deals = calculateTotal(datas, 'total_deals')
                    const total_leads = calculateTotal(datas, 'total_leads')
                    const new_deals = calculateTotal(datas, 'new_deals')
                    const new_leads = calculateTotal(datas, 'new_leads')
                    const renewal_deals = calculateTotal(datas, 'renewal_deals')
                    const renewal_leads = calculateTotal(datas, 'renewal_leads')
                    const closing_total = roundUp((total_deals / total_leads) * 100)
                    const closing_new = roundUp((new_deals / new_leads) * 100)
                    const closing_renewal = roundUp((renewal_deals / renewal_leads) * 100)
                    const lost_leads = calculateTotal(datas, 'lost_leads')
                    const lrl_leads = calculateTotal(datas, 'lrl_leads')
                    const llr_deals = calculateTotal(datas, 'llr_deals')
                    const closing_llr = roundUp((llr_deals / lrl_leads) * 100)
                    const wip_deals = calculateTotal(datas, 'wip_deals')
                    const wip_new = calculateTotal(datas, 'wip_new')
                    const wip_renewal = calculateTotal(datas, 'wip_renewal')
                    const wip_lrwl = calculateTotal(datas, 'wip_lrwl')
                    const wip_others = calculateTotal(datas, 'wip_others')
                    const revenue_target = calculateTotal(datas, 'revenue_target')
                    const revenue_target_achieved = roundUp((calculateTotal(datas, 'total_revenue') / revenue_target) * 100)
                    const revenue_target_no_of_days = calculateTotal(datas, 'revenue_target_no_of_days')
                    const total_target_percentage_met = roundUp((calculateTotal(datas, 'total_revenue') / revenue_target_no_of_days) * 100)
                    const is_target_flag = isFlagTrue(datas, 'is_target_flag')
                    const closing_ratio_ll_and_lrl = roundUp(((calculateTotal(datas, 'lost_leads') + calculateTotal(datas, 'lrl_leads')) / calculateTotal(datas, 'total_leads')) * 100)
                    return {
                        agent_name: 'Total',
                        total_leads,
                        total_deals,
                        new_leads,
                        renewal_leads,
                        llr_leads: calculateTotal(datas, 'llr_leads'),
                        pending_leads: calculateTotal(datas, 'pending_leads'),
                        untouched_customers: calculateTotal(datas, 'untouched_customers'),
                        untouched_leads: calculateTotal(datas, 'untouched_leads'),
                        over_due_tasks: calculateTotal(datas, 'over_due_tasks'),
                        new_deals,
                        renewal_deals,
                        total_deals_cancelled: calculateTotal(datas, 'total_deals_cancelled'),
                        deals_cancellation_payable_amount: currency(roundUp(calculateTotal(datas, 'deals_cancellation_payable_amount'))),
                        total_revenue: currency(roundUp(calculateTotal(datas, 'total_revenue'))),
                        closing_ratio_total: isFinite(closing_total) ? `${roundUp(closing_total)}%` : `0.00%`,
                        closing_ratio_new: isFinite(closing_new) ? `${roundUp(closing_new)}%` : `0.00%`,
                        closing_ratio_renewal: isFinite(closing_renewal) ? `${roundUp(closing_renewal)}%` : `0.00%`,
                        lost_leads,
                        lrl_leads,
                        llr_deals,
                        closing_ratio_llr: isFinite(closing_llr) ? `${roundUp(closing_llr)}%` : `0.00%`,
                        wip_deals,
                        wip_new,
                        wip_renewal,
                        wip_lrwl,
                        wip_others,
                        revenue_target_achieved: isFinite(revenue_target_achieved) ? `${roundUp(revenue_target_achieved)}%` : `0.00%`,
                        target_percentage_met: isFinite(total_target_percentage_met) ? `${roundUp(total_target_percentage_met)}%` : (is_target_flag ? `0.00%` : '-'),
                        revenue_target_no_of_days: isFinite(revenue_target_no_of_days) ? `${currency(roundUp(revenue_target_no_of_days))}` : `-`,
                        is_target_flag:is_target_flag,
                        closing_ratio_lost_leads_and_lead_renewal_lost: isFinite(closing_ratio_ll_and_lrl) ? `${roundUp(closing_ratio_ll_and_lrl)}%` : `0.00%`
                    }
                }
            }

            return {}
        })

        return {
            registration,
            totalTopSalesAgent,
            leadsTarget,
            salesTarget,
            topSalesAgent,
            topSalesAgentInactive,
            user,
            currency,
        }
    },
})
</script>
